.screen{
    width:100vw;
    height:100vh;
    margin:0;
    padding:0;
}

.splashscreen{
    position: fixed;
    top:0px;
    left:0px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo {
        margin-bottom: 150px;
    }
}