.screen.home{
    min-height: 100%;
    width: 100%;
    
    .content{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column wrap;
        .bitmovinplayer-container{
            width:calc( 100vw - 160px );
            margin-top:80px;
        }
    }

}

