.header{
    .toolbar-container {
        width: 100%;
        justify-content: center;
        display: flex;
        flex-direction: row;
        
        .logo{
            height:40px;
            max-width:180px;
        }
    }
}