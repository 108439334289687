.screen.loading{
    position: fixed;
    top:0px;
    left:0px;
    width:100vw;
    height:100vh;
    background: rgba(0,0,0,0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.container_form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    min-height: 100vh;
}

.form{
    background:white;
    border-radius:10px;
    padding: 80px 80px;
    
}

.logo_login{
    max-height:140px;
    margin-top: -320px;
}

.login-form
{
    justify-content: center;
    min-height: 90vh;
}
.button-block{
    width: 100%;
}
.login-background
{
    justify-content: center;
    min-height: 30vh;
    padding: 50px;
}

.powered_by{
    margin-top: 20px;
    text-align: center;
    color: white;
    line-height: 20px;
    .label{
        font-size: 12px;
    }
    .logo_powered{
        width: 120px;
        margin-left: 8px;
    }

}