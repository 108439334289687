.screen.consultant{
    min-height: 100%;
    width: 100%;
    
    .content{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column wrap;
    }
}

